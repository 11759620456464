<script setup>
import { onMounted, onUnmounted } from 'vue'
import axios from 'axios'
import moment from 'moment'

import { DEV_MODE } from '@/main'

/** API */
import { calendarFilter } from '@/api/EowayApi'
import { getGroupUserlist } from '@/api/UserGroup'
import { fastSearch } from '@/api/Eoway'

/** composables */
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'
import { useCalendar, useCalendarFilter, useGesture } from '@/composables'

/** 컴포넌트 */
import FullCalendar from '@fullcalendar/vue3'
import { VBottomSheet, VNavigationDrawer } from 'vuetify/lib/components/index.mjs'
import CalendarHeaderMobile from '@/components/calendar/CalendarHeaderMobile.vue'
import SajuClockPopup from '@/components/calendar/SajuClockPopup.vue'
import ToggleBox from '@/components/calendar/ToggleBox.vue'
import AlertComponent from '@/pages/manse/components/Alert.vue'

definePage({ meta: { layout: 'empty' } })

const { getFiveClass, getGanjiColorFive, ...five } = fiveMixin();
const { ...useFunc } = useFunctions();

/** 로딩 인디케이터 상태 */
const isLoading = ref(false);
/** '사주시계' 팝업 상태 */
const isPopSajuClock = ref(false);
/** '사주시계' 참조 */
const currentClockRef = ref(null);
/** alert 참조 */
const dialogAlert = ref(null);
const dialogTitle = ref('');
const dialogText = ref('');

/** 역학달력 Drawer */
const isDrawerOpen = ref(false);
/** 일진달력 Drawer */
const isTodayLuckDrawerOpen = ref(false);

const isTodayLuckChecked = ref(false);

/** 모바일 사용자 토큰 */
const accessToken = ref(null);
/** 모바일 사용자 ID */
const authUserId = ref(null);
const userId = ref(null);

const isInnerViewOpen = ref(false);

const openInnerView = () => {
  sajuError.value = isInnerViewOpen.value
  // if (!userId.value) return;
  // if (info) selectedDateInfo.value = info;
  if (isInnerViewOpen) {
    // const dataKey = moment(info.date).format('YYYYMMDD');
    // dailyLuckData.value = monthlyLuckData[dataKey];
    isInnerViewOpen.value = true;
  } // 이너뷰 열기
}

const openTodayLuckDrawer = () => {
  sajuError.value = isTodayLuckDrawerOpen.value
  if (isTodayLuckDrawerOpen) {
    isTodayLuckDrawerOpen.value = true;
  }
}

const sajuListParam = ref({
  birthStart: null,
  birthEnd: null,
  startDate: null,
  endDate: null,
  genderOption: 0,
  page: 1,
  size: 10,
  searchKeyWord: '',
  sortOption: null,
  userGroupId: null,
  activeOpt1: false,
  activeOpt2: false,
  activeOpt3: false,
  activeOpt4: false,
  fiveEles: [],
  gender: null,
  filterFlag: false,
  isFavorite: null,
})

const sajuParamData = ref({
  year: '',
  month: '',
  day: '',
  hour: '',
  min: '',
  gender: null,
  userName: '',
  isLunar: false,
  lunar: null,
  isLeapYear: null,
  bornAreaId: null,
  birth_time: null,
  birth: null,
  birthTime: null,
  unknownTime: null,
  option1: true,
  option2: true,
  option3: false,
  option4: true,
  addressTxt: null,
  lang: 'KOR',
  langYN: 'N',
  userGroupId: null,
  shortly: false,
  blCheck: 'N',
});

const sajuData = ref({});
/** '내 사주' 목록 */
const mySajuList = ref({});
/** 선택된 '내 사주' id */
const selectedMySajuId = ref(null);

const sajuError = ref('');

/** 달력 필터 */
const {
  isLunarChecked,
  isSeasonChecked,
  isHolidayChecked,
  isKorChecked,
  isColorChecked
} = useCalendarFilter();

/** 달력 생성 */
const {
  calendarApi,
  calendarOptions,
  refCalendar,
  viewTitle,
  selectedDate,
  firstSeason,
  firstSeasonMonth,
  prev,
  next,
  isToday,
  changeViewTitle,
} = useCalendar({
  isLunarChecked,
  isSeasonChecked,
  isHolidayChecked,
  isKorChecked,
  isColorChecked,
  calendarType: 'mobile',
  isTodayLuckChecked,
  userId,
  sajuData,
  openInnerView,
});

/** 통합 제스처 핸들러 */
const {
  handleStart,
  handleMove,
  handleEnd
} = useGesture({
  onSwipeLeft: next,
  onSwipeRight: prev,
});

/** 역학달력 옵션 */
const toggleOptions = [
  { id: 'lunar', label: '음력', value: isLunarChecked },
  { id: 'season', label: '절기', value: isSeasonChecked },
  { id: 'holiday', label: '공휴일', value: isHolidayChecked },
  { id: 'kor_display', label: '한글표기', value: isKorChecked },
  { id: 'color_display', label: '컬러표시', value: isColorChecked },
];

// const getUserFilter = async (userId) => {
//   try {
//     const result = await calendarFilter({ id: userId })
//   } catch (e) {
//     DEV_MODE && console.log('schedule_app getUserFilter error:', e)
//   }
// }

onMounted(async () => {
  window.addEventListener('message', handleMessage);
})

onUnmounted(() => {
  window.removeEventListener('message', handleMessage);
})

const handleMessage = async (event) => {
  try {
    let data;

    if (typeof event.data === 'string') {
      data = JSON.parse(event.data);
    } else {
      data = event.data;
    }

    // React DevTools 메시지 무시
    if (data.source === 'react-devtools-content-script') {
      console.log('Ignoring React DevTools message.');
      return;
    }

    if (data) {
      accessToken.value = data.accessToken;
      // authUserId.value = data.authUserId;
      // userId.value = data.authUserId;
    }
  } catch (e) {
    console.error('Invalid message received:', `${e}`);
  }
};

const getGrouplistAPI = async () => {
  let url = `/users/userGroups`;
  axios.defaults.headers.common['Authorization'] = accessToken.value;
  const response = await axios.get(url);
  return response.data;
}

const getSajuParams = async (id) => {
  if (!id) return;

  try {
    const result = await getDetail(id);

    const dateTime = result.integrationBirth.split('T');
    const [year, month, day] = dateTime[0].split('-');
    const [hour, min] = dateTime[1]?.split(':');    
    
    sajuParamData.value.year = year;
    sajuParamData.value.month = month.padStart(2, '0');
    sajuParamData.value.day = day.padStart(2, '0');
    sajuParamData.value.hour = hour.padStart(2, '0');
    sajuParamData.value.min = min.padStart(2, '0');
    sajuParamData.value.gender = result.gender === 'MEN' ? 1 : 0;
    sajuParamData.value.userName = result.guestUserName;
    sajuParamData.value.isLunar = result.isLuna === null ? false : result.isLuna;
    sajuParamData.value.lunar = result.lunar;
    sajuParamData.value.isLeapYear = result.isLeapYear;
    sajuParamData.value.bornAreaId = result.bornAreaId;
    sajuParamData.value.birthTime = result.birthTime;
    sajuParamData.value.unknownTime = result.unknownTime
    sajuParamData.value.option1 = result.option1;
    sajuParamData.value.option2 = result.option2;
    sajuParamData.value.option3 = result.option3;
    sajuParamData.value.option4 = result.option4;
    sajuParamData.value.option5 = result.option5;
    sajuParamData.value.addressTxt = result.bornArea;
    // sajuParamData.value.lang = result.lang
    // sajuParamData.value.langYN = result.langYN
    // sajuParamData.value.userGroupId = result.userGroupId
  } catch (e) {
    DEV_MODE && console.error('사주정보 조회 오류:', e);
    sajuError.value = e;
  };
};

const padNumber = (num) => num.toString().padStart(2, '0');

const convertManseForceParams = () => {
  const { year, month, day, hour, min } = sajuParamData.value;

  sajuParamData.value.birth = `${year}-${padNumber(month)}-${padNumber(day)}`;  
  
  if (sajuParamData.value.birthTime === '25:00') {
    sajuParamData.value.unknownTime = 'Y';
  } else {
    sajuParamData.value.birthTime = `${padNumber(hour)}:${padNumber(min)}`;
  }
  if (!sajuParamData.value.unknownTime) sajuParamData.value.unknownTime = 'N';
  if (!sajuParamData.value.isLeapYear) sajuParamData.value.isLeapYear = false;
  if (sajuParamData.value.bornAreaId === null) delete sajuParamData.value.bornAreaId;
}

const getManseForce = async () => {
  convertManseForceParams();

  try {
    const result = await fastSearch(sajuParamData.value);    
    sajuData.value = result;
  } catch (e) {
    DEV_MODE && console.error('만세력 조회 오류:', e);
    sajuError.value = `getManseForce: ${e}`;
  }
}



const showDialogAlert = (title, text = '', nextUrl = '') => {
  dialogTitle.value = title;
  dialogText.value = text;
  dialogAlert.value.openAlertDialog();
};

watch (() => accessToken.value, async (newToken) => {
  if (DEV_MODE && newToken) {

    try {
      const groupList = await getGrouplistAPI();

      if (groupList && groupList.length > 0) {
        sajuListParam.userGroupId = groupList[0].userGroupId;
        const result = await getGroupUserlist(sajuListParam);

        mySajuList.value = result.content;
        if (mySajuList.value && mySajuList.value.length > 0) {
          const guestUserId = mySajuList.value[mySajuList.value.length - 1].guestUserId;
          if (guestUserId) {
            await getSajuParams(guestUserId);
            await getManseForce();
          }
        }
      }
    } catch (e) {
      sajuError.value = e
    }
  }
})

// onMounted(() => {
// window.addEventListener('message', (e) => {
//     // const message = JSON.parse(e.data);
//     // console.log('message1');
//     console.log(e?.data);
//     if (!e.data.userId) return
//     getUserFilter(e?.data?.userId);
//   });
//   document.addEventListener('message', (e) => {
//     // const message = JSON.parse(e.data);
//     // console.log('message2');
//     console.log(e?.data);
//     if (!e.data.userId) return
//     getUserFilter(e?.data?.userId);
//   });
// })

// watch(selectedPickerDate, (newValue) => {
//   console.log('========================================')
//   console.log('newValue:', newValue)
//   console.log('========================================')
// })

/** TODAY 버튼 클릭 */
const today = () => {
  refCalendar.value.getApi().gotoDate(new Date())
  selectedDate.value = moment().format('YYYY-MM-DD')
  changeViewTitle();
};

/** 사주시계 팝업  */
const setSajuClock = (state) => (isPopSajuClock.value = state);

const clickBottomLabel = () => {
  showDialogAlert('일진달력 서비스 오픈 준비 중\n여러분의 일상에 새로운 의미를 더해드릴게요.\n많은 기대와 관심으로 조금만 기다려주세요!');
  // if (isTodayLuckChecked.value) {
  //   isTodayLuckChecked.value = false;
  // } else {
  //   if (!DEV_MODE) showDialogAlert('준비중입니다');
  //   else isTodayLuckChecked.value = true;
  // };
}

/** '내 사주' 클릭 */
const selectMySaju = async (id) => {
  selectedMySajuId.value = id;
  userId.value = id;
}

const confirmSelectSaju = async () => {
  // closePopMySaju();
  isTodayLuckDrawerOpen.value = false;
  if (selectedMySajuId) {
    await getSajuParams(selectedMySajuId.value);
    await getManseForce();
    calendarApi.value.render();
  }
}
</script>

<template>
  <div class="calendar-wrap">
    <!-- sajuParamData: {{ sajuParamData }}
    sajuerror: {{ sajuError }} -->
    <!-- <VNavigationDrawer
      v-if="isTodayLuckDrawerOpen && DEV_MODE"
      v-model="isTodayLuckDrawerOpen"
      app
      temporary
      location="right"
      width="100%"
      class="drawer-mobile-w100"
    >
      <div style="background-color: #fff;">
        <div>
          <button @click="isTodayLuckDrawerOpen = false">닫기</button>
          <div class="popup-container">
            <div class="header">
              <div class="title">내 사주 선택</div>
              <button class="close" @click="isTodayLuckDrawerOpen = false"></button>
            </div>

            <div class="popup-body">
              <div class="section">
                * 저장목록 > 내사주 그룹에 있는 사주만 선택 가능합니다.<br>
                * 등록 후, 변경이 어려우니 신중해 주세요!
              </div>

              <div v-if="!mySajuList || mySajuList.length === 0" class="section empty">
                <div>
                  사주를 [내 사주] 그룹으로<br>새롭게 저장해 주세요!
                </div>
                <button class="add-button" @click="clickRoute">사주 조회하러 가기</button>
              </div>

              <div v-else class="section list">
                <div
                  v-for="(item, index) in mySajuList"
                  :key="index" 
                  :class="['mysaju-item-box', 'a-link', {'selected': selectedMySajuId === item.guestUserId}]"
                  @click="selectMySaju(item.guestUserId)"
                >
                  <div class="ganji-box-l">
                    <span
                      class="first ganji-txt1"
                      :class="five.getColorFive(five.formatGanJi(item?.sajugangi, 2))"
                      >{{ five.formatGanJi(item?.sajugangi, 2) }}
                    </span>
                    <span
                      class="second ganji-txt1"
                      :class="five.getColorFive(five.formatGanJi(item?.sajugangi, 6))"
                      >{{ five.formatGanJi(item?.sajugangi, 6) }}</span
                    >
                  </div>

                  <div class="birth-box">
                    <div class="name-box row">
                      <span v-if="item.guestUserName" class="name">{{ item.guestUserName }}</span>
                      <span v-else class="name">-</span>
                      <span class="age"> {{ useFunc.getGenderTxt(item.gender) }} {{ item?.ageKor }}세 (만{{ item?.age }}세) </span>
                    </div>

                    <div class="name-row">
                      <div class="birth-item">
                        <span class="label solar">양력</span>
                        <span class="txts"
                          >{{ useFunc.formatCustomDate(item?.birth) }}({{
                            useFunc.getDayOfWeekOne(item.birth)
                          }}) {{ useFunc.getBirthTimeTxt(item.birthTime) }}
                        </span>
                      </div>

                      <div class="birth-item">
                        <span class="label lunar">음력</span>
                        <span class="txts">{{ useFunc.formatCustomDate(item?.lunar) }}</span>
                      </div>
                    </div>

                    <div class="birth-item">
                      <span class="label">옵션</span>
                      <span class="txts">
                        {{ item?.bornAreaInfo?.korCityName }}
                        <span v-if="item?.bornAreaInfo?.korCityName">,</span>
                        {{ useFunc.formatOption4Txt(item?.option4) }}
                        {{ useFunc.formatOption1Txt(item?.option1) }}
                        {{ useFunc.formatOption2Txt(item?.option2) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div v-if="mySajuList && mySajuList.length !== 0" class="footer">
              <button 
                :class="['confirm', {'active': selectedMySajuId}]"
                :disabled="!selectedMySajuId"
                @click="confirmSelectSaju"
              >
                완료
              </button>
            </div>
          </div>
        </div>
      </div>
    </VNavigationDrawer> -->
    <VNavigationDrawer
      v-if="isDrawerOpen"
      v-model="isDrawerOpen"
      app
      temporary
      location="right"
      class="drawer-mobile"
    >
      <div class="drawer-header">
        역학달력 옵션
        <button class="close" @click="isDrawerOpen = false"></button>
      </div>
      <ToggleBox :options="toggleOptions"/>
    </VNavigationDrawer>
    <!-- <button v-if="DEV_MODE" @click="openTodayLuckDrawer">드로어{{ selectedMySajuId }}</button>
    <button v-if="DEV_MODE" @click="openInnerView">하단뷰</button>
    <VBottomSheet v-model="isInnerViewOpen" height="100vh" class="bottom-sheet">
      <div class="bottom-view">
        message: {{ accessToken }}
        message: {{ authUserId }}
        message: {{ sajuError }}
      </div>
    </VBottomSheet> -->

    <div class="content-main">
      <section class="inner-base">
        <div>
          <CalendarHeaderMobile
            :calendarType="'mobile'"
            :title="viewTitle"
            :isToday="isToday()"
            :solarTerm="firstSeason"
            :solarTermMonth="firstSeasonMonth"
            :isColorChecked="isColorChecked"
            @prev="prev"
            @next="next"
            @today="today"
            @clock="setSajuClock(true)"
            @option="isDrawerOpen = true"
          />

          <div class="sec-sch-body mobile">
            <v-row
              class="fill-height"
              @mousedown="handleStart"
              @mousemove="handleMove"
              @mouseup="handleEnd"
              @touchstart="handleStart"
              @touchmove="handleMove"
              @touchend="handleEnd"
            >
              <v-col>
                <v-sheet>
                  <FullCalendar
                    ref="refCalendar"
                    class="full-calendar mobile"
                    :options="calendarOptions"
                  />
                </v-sheet>
              </v-col>
            </v-row>
          </div>

          <div class="bottom-label" :class="{default: isTodayLuckChecked}">
            <div class="summary">{{ isTodayLuckChecked ? '역학으로 보는 현재시간과 절기!' : '나의 일진이 궁금하다면?' }}</div>
            <button class="button" @click="clickBottomLabel">{{isTodayLuckChecked ? '편리한 역학달력' : '일진달력' }}<i class="arrow"></i></button>
          </div>
        </div>
      </section>
    </div>

    <div v-if="isLoading" class="loading-full-wrap">
      <div class="inner-box">
        <div ref="loadingContainer" class="loading-container">
          <v-progress-circular :size="50" :width="3" color="primary" indeterminate />
        </div>
      </div>
    </div>
  
    <SajuClockPopup
      v-if="isPopSajuClock"
      ref="currentClockRef"
      @closeClock="setSajuClock(false)"
    />
  
    <AlertComponent
      ref="dialogAlert"
      :title="dialogTitle"
      :text="dialogText"
    />
  </div>
</template>

<style>
.v-dialog-alert .alert-wrap .tit-box {
  white-space: pre-wrap;
}
</style>


<style lang="scss">
.intro-layout {
  width: 100vw;
  height: 100%;
  .main {
    padding: 100px !important;
    margin: 0 !important;
  }
}

/** FullCalendar 격자 설정 */
.fc-theme-standard .fc-scrollgrid {
  border: 0px solid var(--fc-border-color);
}
.fc-theme-standard th {
  border: 0px solid var(--fc-border-color);
}
.fc-theme-standard td {
  border: 0px solid var(--fc-border-color);
  border-top: 1px solid #d3d3d3 !important;
}
/** FullCalendar 오늘 날짜 dayCell */
.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent;
}

/* .v-calendar-monthly .v-calendar-weekly__head-weekday {
  height: 38px;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #23252E;
  font-size: 13px;
  font-weight: 500;
  border-bottom: 0.5px solid #E2E2E2;
} */
/* .v-calendar-weekly__day-label {
  margin: 0 !important;
} */
/* .v-calendar-weekly__day-label button.v-btn {
  display: none;
  margin:0 !important
} */
.datePicker .v-card__text {
  padding: 20px !important;
}

.fc-day-sun {
  color: #eb4f5d;
}
.fc-day-sat {
  color: #5a6ef6;
}
.fc-col-header-cell {
  .fc-scrollgrid-sync-inner {
    .fc-col-header-cell-cushion {
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    a {
      color: inherit;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

.fc .fc-daygrid-day-top {
  position: absolute;
  top: 4px;
  width: 100%;
  height: 20px;
  align-items: center;
  justify-content: center;
}

.custom-day-cell {
  width: 20px;
  height: 20px;
  font-size: 14px;
  border-radius: 100%;
  margin: 0 auto;
  font-weight: 400;
  text-align: center;
  color: #23252e;
}
.custom-day-sunday,
.custom-day-holiday {
  color: #eb4f5d;
}
.custom-day-saturday {
  color: #5a6ef6;
}
.custom-day-highlight {
  background-color: #eb4c10;
  color: white;
}
.custom-day-selected {
  background-color: #e1e1e1;
}
.custom-event-content {
  overflow: hidden;
  flex-wrap: wrap;
}
// .custom-content-cell {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: space-between;
// }
.content-dayju {
  height: 18px;
  color: #23252e;
}
.txt-chn {
  font-size: 16px;
}
.txt-kor {
  font-size: 14px;
}
.content-lunar {
  left: 0;
  right: 0;
}
.content-holiday {
  font-size: 12px;
  line-height: 14px;
  left: 0;
  right: 0;
  margin-bottom: 0 !important;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}
.content-season {
  left: 0;
  right: 0;
}

.fc-daygrid-dot-event {
  padding: 0;
}

.custom-day-cell.other {
  position: absolute;
  transform: translateX(-10px);
  width: 20px;
  height: 20px;
  font-size: 14px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  text-align: center;
}

.v-col-xxl,
.v-col-xxl-auto,
.v-col-xxl-12,
.v-col-xxl-11,
.v-col-xxl-10,
.v-col-xxl-9,
.v-col-xxl-8,
.v-col-xxl-7,
.v-col-xxl-6,
.v-col-xxl-5,
.v-col-xxl-4,
.v-col-xxl-3,
.v-col-xxl-2,
.v-col-xxl-1,
.v-col-xl,
.v-col-xl-auto,
.v-col-xl-12,
.v-col-xl-11,
.v-col-xl-10,
.v-col-xl-9,
.v-col-xl-8,
.v-col-xl-7,
.v-col-xl-6,
.v-col-xl-5,
.v-col-xl-4,
.v-col-xl-3,
.v-col-xl-2,
.v-col-xl-1,
.v-col-lg,
.v-col-lg-auto,
.v-col-lg-12,
.v-col-lg-11,
.v-col-lg-10,
.v-col-lg-9,
.v-col-lg-8,
.v-col-lg-7,
.v-col-lg-6,
.v-col-lg-5,
.v-col-lg-4,
.v-col-lg-3,
.v-col-lg-2,
.v-col-lg-1,
.v-col-md,
.v-col-md-auto,
.v-col-md-12,
.v-col-md-11,
.v-col-md-10,
.v-col-md-9,
.v-col-md-8,
.v-col-md-7,
.v-col-md-6,
.v-col-md-5,
.v-col-md-4,
.v-col-md-3,
.v-col-md-2,
.v-col-md-1,
.v-col-sm,
.v-col-sm-auto,
.v-col-sm-12,
.v-col-sm-11,
.v-col-sm-10,
.v-col-sm-9,
.v-col-sm-8,
.v-col-sm-7,
.v-col-sm-6,
.v-col-sm-5,
.v-col-sm-4,
.v-col-sm-3,
.v-col-sm-2,
.v-col-sm-1,
.v-col,
.v-col-auto,
.v-col-12,
.v-col-11,
.v-col-10,
.v-col-9,
.v-col-8,
.v-col-7,
.v-col-6,
.v-col-5,
.v-col-4,
.v-col-3,
.v-col-2,
.v-col-1 {
  width: 100%;
  padding: 0px;
}
table tbody tr {
  height: 3px;
}
</style>

<style lang="scss">
@media (max-width: 767px) {
  body {
    max-width: 100%;
  }
}
</style>
<style lang="scss">
a.fc-event:focus {
  outline: none !important;
  box-shadow: none !important;
}

.v-dialog {
  &.dialog-st1 {
    border-radius: 16px;
  }
}

.pop-close-box {
  position: relative;
  .close-button {
    position: absolute;
    right: 16px;
    top: 20px;
  }
}

.close-button {
  position: relative;
  width: 15px;
  height: 15px;
  cursor: pointer;
  font-size: 0;
  display: block;
  &.sm {
    width: 10px;
    height: 10px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1.5px;
    background-color: #2c2c2e;
  }

  &::before {
    transform: translateY(-50%) rotate(45deg);
  }

  &::after {
    transform: translateY(-50%) rotate(-45deg);
  }
}

.obtn {
  background: #eb4c10 !important;
  color: #ffffff !important;
  &.w100 {
    width: 100%;
  }
  &.w-xl {
    width: 160px;
    height: 48px;
    font-size: 15px;
  }
  &.cancel {
    background: #fff !important;
    color: #333333 !important;
    font-weight: 700;
  }
  &.large {
    height: 48px;
  }
  &.disabled-button {
    background: #949494 !important;
  }
}

.clock-box {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 4px;
  &.just-bt {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    letter-spacing: -0.35px;
    font-size: 14px;
  }
  .date-box {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
    justify-content: flex-start;
    span {
      margin-left: 2px;
    }
  }
}

.time-clock-box dl {
  display: flex;
  width: 74px;
  flex-wrap: wrap;
  dt {
    width: 50%;
    text-align: right;
    color: #757575;
    font-size: 12px;
  }
  dd {
    width: 50%;
    display: flex;
    font-size: 13px;
    color: #333333;
    padding-left: 8px;
    box-sizing: border-box;
    font-weight: 500;
  }
}

.date-box.solar {
  margin-right: 8px;

  .name {
    background-color: #e57576;
    color: #fff;
    width: 47px;
    height: 24px;
    font-size: 14px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.none-bg {
      background: transparent;
      color: #ff6e6e;
      width: auto;
      height: auto;
      font-weight: 500;
    }
  }
}
.date-box.lunar {
  .name {
    background-color: #759ae5;
    color: #fff;
    width: 47px;
    height: 24px;
    font-size: 14px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.none-bg {
      background: transparent;
      color: #5790fc;
      width: auto;
      height: auto;
      font-weight: 500;
      // margin-left: 8px;
    }
  }
}
.clock-ganji-box {
  font-size: 14px;
  color: #757575;
  .year-item-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
}

.data-clock-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  // padding: 0 8px;
  .data-row-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
  }
  .data-val {
    width: 54px;
    height: 54px;
    background: #eeeeee;
    font-size: 32px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    font-family: 'ChosunGs';
  }
}

.pop-body {
  padding: 1rem;
  padding-top: 0;
  position: relative;
  height: calc(100% - 45px);
  box-sizing: border-box;
  &.pd-mid {
    padding-left: 20px;
    padding-right: 20px;
  }
  &.pd-large {
    padding-left: 32px;
    padding-right: 32px;
  }
  &.pd-large-btm {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;
  }
  &.h-100 {
    height: 100%;
  }
  &.pd-top-none {
    padding-top: 0;
  }
  &.pd- .tit-s {
    color: #23252e;
    font-weight: 500;
    font-size: 15px;
    position: relative;
    height: 34px;
  }
  .pop-exp {
    color: #eb4c10;
    font-size: 14px;
    margin-bottom: 26px;
  }
  .btn-right {
    position: absolute;
    right: 0;
    top: 0;
  }
  .icon-minus {
    width: 34px;
    height: 34px;
    position: relative;
    display: block;
    font-size: 0;
    &::before {
      width: 16px;
      height: 2px;
      background: #23252e;
      border-radius: 4px;
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -1px;
      margin-left: -8px;
    }
  }
  .btn-btm-long {
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    right: 1rem;
  }
  .pop-form-box.option {
    padding: 0 1rem;
    .row-box + .row-box {
      margin-top: 15px;
    }
  }
  .pop-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 48px;
    background: #f8f8f8 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 16px 16px;
    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-items: center;
      height: 100%;
      .txt1 {
        color: #575757;
        font-size: 14px;
      }
      .txt2 {
        color: #eb4c0f;
        font-size: 14px;
      }
      .txt3 {
        color: #2c2c2e;
        font-size: 14px;
        font-weight: 500;
      }
      .count {
        width: 50%;
      }
      .order {
        width: 50%;
        button {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
    }
  }
}
.pop-search-list {
  min-height: 340px;
}

.pop-body-tit {
  color: #2c2c2e;
  font-size: 15px;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  line-height: 1.5;
}

.pop-tit-box {
  display: flex;
  height: 45px;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  position: relative;
  font-size: 18px;
  color: #2c2c2e;
  font-weight: 500;
  &.flex-sb {
    justify-content: space-between;
  }
  &.pop-right-close {
    padding-right: 50px;
  }
  .pop-tit-sub {
    font-size: 15px;
    &.fnt16 {
      font-size: 16px;
      font-weight: 700;
      color: #2c2c2e;
    }
  }
  &.st-short {
    height: 0;
    position: relative;
  }
  .close-button {
    position: absolute;
    right: 1rem;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.pop-inner {
  background: #fff;
  z-index: 1;
  padding-top: 16px;
  &.bg01 {
    background-color: #f4f5f7;
  }
  &.scroll {
    overflow-y: auto;
  }
  &.none-pd {
    padding: 0;
  }
}
.v-dialog {
  &.dialog-st1 {
    border-radius: 16px;
  }
}
.fc-daygrid-event {
  box-shadow: none !important;
}
.fc-event::before,
.fc-event::after,
.custom-event-content::before,
.custom-event-content::after {
  // content: none !important;
  // display: none !important;
  background: transparent !important;
  box-shadow: none !important;
}
</style>

<style>
.scroll-to-top {
  display: none !important;
}

.v-bottom-sheet__content.v-overlay__content {
  background-color: #fff !important;
  /* top: 0; */
  margin-top: 0;
  max-height: 99vh;
}
</style>