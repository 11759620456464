<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'mypage' } })
import { paymentOwnDetail, subscrCancelReason, subscrCancel, paymentCancel } from '@/api/PaymentApi'
import { formatCustomDate } from '@/composables/functions'
import { ref, defineEmits } from 'vue'

//Alert
import AlertComponent from '@/pages/manse/components/Alert.vue'

const route = useRoute()
const router = useRouter()

const payOwnData = ref(null)
const id = ref(null)
const stepMode = ref('step1')
const productType = ref(null)
const tabType = ref('IMG')

//TODO: 목록
const getView = async () => {
  const params = {}
  params.productType = productType.value
  const data = await paymentOwnDetail(id.value, params)
  payOwnData.value = data
}

const popUnsubscribeBox = ref(false)

onMounted(async () => {
  id.value = route.params?.id
  productType.value = route.query.productType
  tabType.value = route.query.tabType

  await getView()
  await reasonList()
})
const getList = () => {
  router.push(`/mypage/subscrPayment?tabType=${tabType.value}&productType=${productType.value}`)
}

const goReceipt = () => {
  window.open(`${payOwnData.value?.receiptUrl}`, '_blank')
}

// 숫자에 천 단위 구분 쉼표를 추가하는 함수
const formatNumber = (num) => {
  if (num < 1000) return num
  return new Intl.NumberFormat().format(num)
}

const openUnsubscribe = () => {
  stepMode.value = 'step1'
  popUnsubscribeBox.value = true
}
const close = () => {
  popUnsubscribeBox.value = false
}
const articleContent = ref(null)
const contentPlaceholder = `서비스 향상에 도움이 되도록 구체적인 탈퇴 이유를 적어주시면 감사하겠습니다.`

const reasonListData = ref(null)
const selectedReason = ref(null)

const reasonList = async () => {
  const params = { size: -1, page: 1 }
  const data = await subscrCancelReason(params)
  reasonListData.value = data.content
}

const maxCharCount = 200
const charCount = computed(() => articleContent.value?.length || 0) // 기본값 0

const checkMaxLength = () => {
  if (articleContent.value.length > maxCharCount) {
    articleContent.value = articleContent.value.slice(0, maxCharCount)
  }
}

const isTextareaDisabled = computed(() => {
  return selectedReason.value !== 7
})

const next = () => {
  if (selectedReason.value === null) {
    //showDialogAlert('해지 사유를 선택해주세요.')
    //openUnsubscribe()
    stepMode.value = 'step2'
  } else {
    stepMode.value = 'step2'
  }
}

const save = async () => {
  const params = {
    reasonId: selectedReason.value,
    ...(articleContent?.value !== null && { customReason: articleContent.value }),
  }

  const data = await subscrCancel(params)
  if (data?.status === 'success') {
    showDialogAlert('정상적으로 정기결제가 해지되었습니다.', '', '/mypage/subscrPayment')
    //router.push('/mypage/subscrPayment')
  } else {
    showDialogAlert(data?.message)
  }
}

const dialogAlert = ref(null)
const dialogTitle = ref('')
const dialogTitle2 = ref('')
const dialogText = ref('')
const dialogNextUrl = ref(null)

const dialogConfrim = ref(null)

//FIX 알림창
const showDialogAlert = (title, text = '', nextUrl = '') => {
  dialogTitle.value = title
  if (text) dialogText.value = text
  dialogNextUrl.value = nextUrl
  dialogAlert.value.openAlertDialog()
}

const paymentPointCancel = async (orderId) => {
  if (confirm('취소 하시겠습니까? ')) {
    const params = {}
    try {
      const data = await paymentCancel(orderId, params)
      if (data?.orderStatus) {
        getList()
      }
    } catch {
      showDialogAlert('오류가 발생했습니다.')
    }
  }
}
const emit = defineEmits(['router-change-event'])

const test = () => {
  emit('router-change-event')
  alert(5)
}
</script>

<template>
  <div class="my-info-wrap">
    <div class="my-tit-box com-bg-my-box h-small">
      <div class="tit">이용중인 서비스</div>
      <div class="path">
        <ul>
          <li>홈</li>
          <li>마이페이지</li>
          <li>낭만 저장소</li>
          <li @click="test()">이용중인 서비스</li>
        </ul>
      </div>
    </div>
    <div class="myinfo-box com-bg-my-box">
      <!-- {{ payOwnData }} -->

      <div class="payment-detail-box">
        <div class="order-box">
          {{ payOwnData?.orderId }}
        </div>
        <div class="title-info-box">
          <div class="text-box">
            <div class="date">{{ formatCustomDate(payOwnData?.approvedAt) }}</div>
            <div class="title">
              <span v-if="payOwnData?.productDetail?.productType === 'POINT'">
                포인트 충전 {{ payOwnData?.productName }}
              </span>
              <span v-else="payOwnData?.productDetail?.productType === 'POINT'">{{
                payOwnData?.productName
              }}</span>
            </div>

            <div class="date2" v-if="payOwnData?.productDetail?.productType !== 'POINT'">
              {{ formatCustomDate(payOwnData?.productDetail?.startDate) }} ~
              {{
                payOwnData?.productDetail?.endDate
                  ? formatCustomDate(payOwnData?.productDetail?.endDate)
                  : ''
              }}
            </div>
          </div>
          <div class="btn-box">
            <button
              v-if="
                payOwnData?.productDetail?.productType === 'SUBSCRIPTION' &&
                payOwnData?.productDetail?.planStatus === 'ACTIVE' &&
                payOwnData?.productDetail?.subscriptionCancelDate === null
              "
              @click="openUnsubscribe()"
            >
              구독해지
            </button>
            <span class="txt1" v-if="payOwnData?.productDetail?.subscriptionCancelDate">
              구독해지일자 {{ payOwnData?.productDetail?.subscriptionCancelDate }}</span
            >
            <button
              v-if="
                payOwnData?.productDetail?.productType === 'POINT' &&
                payOwnData?.productDetail?.usedAt === null
              "
              @click="paymentPointCancel(payOwnData.orderId)"
            >
              취소하기
            </button>
            <span
              class="txt1"
              v-if="
                payOwnData?.productDetail?.productType === 'POINT' &&
                payOwnData?.productDetail?.usedAt !== null
              "
            >
              취소일자 {{ payOwnData?.productDetail?.usedAt }}</span
            >
            <!-- <span v-if="payOwnData?.productType === 'SUBSCRIPTION' "></span> -->
          </div>
        </div>

        <div class="tit-box">
          <div class="text-box">결제내역</div>
          <div class="btn-box">
            <span v-if="payOwnData?.productDetail?.productType !== 'POINT'">
              <button class="line">결제수단변경</button>
            </span>
            <button @click="goReceipt()">영수증 보기</button>
          </div>
        </div>

        <div class="detail-box">
          <dl class="solid-line">
            <dt>실 결제금액</dt>
            <dd>
              <div class="">{{ formatNumber(payOwnData?.totalAmount) }}원</div>
              <div class="">
                {{ payOwnData?.paymentDetail?.cardCompany?.cardCompany }} /
                {{
                  payOwnData?.paymentDetail?.cardCompany?.installmentPlanMonths === 0
                    ? '일시불'
                    : payOwnData?.paymentDetail?.cardCompany?.installmentPlanMonths
                }}
              </div>
            </dd>
          </dl>
          <dl>
            <dt>정상가</dt>
            <dd>{{ formatNumber(payOwnData?.basePrice) }}원</dd>
          </dl>
          <dl>
            <dt>혜택</dt>
            <dd>{{ formatNumber(payOwnData?.salePrice) }}원</dd>
          </dl>
          <dl class="solid-line">
            <dt>합계</dt>
            <dd>{{ formatNumber(payOwnData?.amount) }}원</dd>
          </dl>
          <dl v-if="payOwnData?.productDetail?.productType === 'POINT'" class="solid-line-2">
            <dt>포인트 전환</dt>
            <dd>{{ formatNumber(payOwnData?.productDetail?.total_amount) }}P</dd>
          </dl>
          <dl v-if="payOwnData?.productDetail?.productType === 'POINT'">
            <dt>구매포인트</dt>
            <dd>{{ formatNumber(payOwnData?.productDetail?.amount) }}P</dd>
          </dl>

          <dl
            v-if="
              payOwnData?.productDetail?.productType === 'POINT' &&
              payOwnData?.productDetail?.bonusAmount
            "
          >
            <dt>
              보너스포인트
              <span v-if="payOwnData?.productDetail?.expiryDate" class="txt-red">
                유효기간 {{ payOwnData?.productDetail?.expiryDate }}
              </span>
            </dt>
            <dd>{{ formatNumber(payOwnData?.productDetail?.bonusAmount) }} P</dd>
          </dl>
        </div>
        <div class="point-info-box st-payment">
          <ul v-if="payOwnData?.productDetail?.productType === 'POINT'">
            <li>포인트 취소는 사용하지 않은 포인트 금액에 한하여 7일 이내 가능합니다.</li>
          </ul>
          <ul v-else>
            <li>
              자동결제는 월단위로 결제가 되며, 첫 결제하신 날짜와 동일한 날짜에 결제가 이루어집니다.
              해당일이 없는 경우, 해당월의 마지막날에 결제가 이루어 집니다.
            </li>
            <li>
              결제 후 미사용 시 7일 이내에 환불이 가능합니다.
              <button class="btn-txt-line">환불안내 바로가기</button>
            </li>
          </ul>
        </div>
        <div class="btn-com gap-xl ceneter">
          <button class="btn-line w-large bg-w w-mid" @click="getList()">목록으로</button>
        </div>
      </div>
    </div>
  </div>

  <VDialog
    v-model="popUnsubscribeBox"
    class="v-dialog-sm dialog-st1"
    max-width="400px"
    @click:outside="close"
  >
    <VCard class="br16">
      <div class="pop-inner padding-tb">
        <div class="pop-tit-box tit-default">
          <div class="tit-text-d">구독해지</div>
          <button class="dial-close" @click="close"></button>
        </div>
        <div class="pop-body-box st-pd-32" v-if="stepMode === 'step1'">
          <div class="pop-sub-title-box">낭만 만세력 이용권을 해지하시려는 이유를 알려주세요.</div>
          <div class="pop-reason-box">
            <div v-for="reason in reasonListData" class="row-box">
              <input
                :id="reason.reason_id"
                type="radio"
                class="radio"
                name="ques_type"
                v-model="selectedReason"
                :value="reason.reason_id"
                @change="handleChange"
              /><label :for="reason.reason_id" class="label font">{{ reason.reason }}</label>
            </div>
          </div>
          <div class="text-box">
            <v-textarea
              v-model="articleContent"
              :placeholder="contentPlaceholder"
              outlined
              no-resize
              rows="3"
              class="textarea custom-textarea"
              @input="checkMaxLength"
              :disabled="isTextareaDisabled"
            />
            <div class="char-count">{{ charCount }} / {{ maxCharCount }}</div>
          </div>
          <div class="pop-btn">
            <VRow>
              <VCol md="4" cols="12" class="pa-1">
                <VBtn type="submit" width="100%" @click="close"> 해지안함 </VBtn></VCol
              >
              <VCol md="8" cols="12" class="pa-1"
                ><VBtn
                  type="submit"
                  width="100%"
                  variant="outlined"
                  color="info"
                  :disabled="agree === false"
                  style="font-weight: 700"
                  @click="next()"
                >
                  해지 계속 진행
                </VBtn></VCol
              >
            </VRow>
          </div>
        </div>
        <div class="pop-body-box st-pd-32" v-if="stepMode === 'step2'">
          <div class="pop-sub-title-box bg-gray">
            현재 이용권 만료일은
            <span class="txt-point">
              {{ payOwnData?.endDate ? formatCustomDate(payOwnData.endDate) : '' }}</span
            >
            입니다.
          </div>
          <div class="step2-txt-01">정기결제를 해지 하시겠습니까?</div>
          <div class="step2-txt-02">
            정기결제를 해지해도 현재 사용 중인 이용권의 <br />
            만료일까지 추가 사주저장을 사용할 수 있습니다.
          </div>
          <div class="step2-txt-03">
            <div class="txt-nor">
              이용권 만료 후 사주 저장을 하는 경우, 일부 제약이 있을 수 있습니다.
            </div>
            <div class="txt-s">- 즐겨찾기 기능 <br />- 그룹관리 기능</div>
          </div>
          <div class="step2-txt-04">
            정기결제를 유지하면 자동으로 결제되어 <br />만료일 걱정없이 편하게 이용하실 수 있습니다.
          </div>
          <div class="pop-btn step-2">
            <VBtn type="submit" width="100%" @click="save"> 해지하기 </VBtn>
          </div>
        </div>
      </div>
    </VCard>
  </VDialog>
  <!--Alert창-->
  <AlertComponent
    ref="dialogAlert"
    :title="dialogTitle"
    :text="dialogText"
    :nextUrl="dialogNextUrl"
  />
</template>
