<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'mypage' } })
import useFunctions from '@/composables/useFunctions'
import { useRouter } from 'vue-router'
import { formatCustomDate } from '@/composables/functions'

const router = useRouter()
const { ...useFun } = useFunctions()
const emit = defineEmits()

const props = defineProps({
  prPayOwnList: {
    type: Array,
    required: true,
  },
  tabType: {
    type: String,
    required: true,
  },
})

const goPayView = (orderId, productType) => {
  router.push(
    `/mypage/subscrPaymentView/${orderId}?tabType=${props.tabType}&productType=${productType}`,
  )
}

// 숫자에 천 단위 구분 쉼표를 추가하는 함수
const formatNumber = (num) => {
  if (num < 1000) return num
  return new Intl.NumberFormat().format(num)
}
</script>
<template>
  <div
    class="row-box"
    v-for="(item, index) in prPayOwnList"
    :key="index"
    @click="goPayView(item?.productDetail?.productTypeId, item?.productDetail?.productType)"
  >
    <div class="text-box">
      <div class="date">{{ formatCustomDate(item?.approvedAt) }}</div>
      <div class="title">
        <span v-if="item?.productDetail?.productType === 'POINT'">
          포인트 충전 {{ item?.productName }}
        </span>
        <span v-else="item?.productDetail?.productType === 'POINT'">{{ item?.productName }}</span>
      </div>
      <div v-if="item?.productDetail?.productType === 'POINT'">
        <span v-if="item?.productDetail?.bonusAmount"
          >보너스 포인트 {{ formatNumber(item?.productDetail?.bonusAmount) }} P</span
        >

        <span v-if="item?.productDetail?.expiryDate" class="txt-red">
          유효기간 {{ item?.productDetail?.expiryDate }}
        </span>
      </div>
      <div v-else>
        <div class="date2">
          {{ formatCustomDate(item?.productDetail?.startDate) }} ~
          {{ item?.productDetail?.endDate ? formatCustomDate(item?.productDetail?.endDate) : '' }}
        </div>
      </div>
    </div>

    <div class="price">
      <div class="txt">
        <span
          :class="
            item?.productDetail?.usedAt !== null && item?.productDetail?.productType === 'POINT'
              ? 'line'
              : ''
          "
        >
          <span v-if="item?.productDetail?.productType === 'POINT'">
            {{ formatNumber(item?.productDetail?.totalAmount) }}
          </span>
          <span v-else>
            {{ formatNumber(item?.totalAmount) }}
          </span>
          {{ item?.productDetail?.productType === 'POINT' ? 'P' : '원' }}
        </span>
        <span class="txt-red" v-if="item?.productDetail?.usedAt">취소완료</span>
      </div>
      <i class="icon-arrow"></i>
    </div>
  </div>
</template>
