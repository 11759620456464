<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'mypage' } })
import { paymentOwn, ownPoint } from '@/api/PaymentApi'
import { formatCustomDate } from '@/composables/functions'

import IconService01 from '@/components/svg/IconService01.vue'
import IconService02 from '@/components/svg/IconService02.vue'
import IconService03 from '@/components/svg/IconService03.vue'
import IconService04 from '@/components/svg/IconService04.vue'
import IconService05 from '@/components/svg/IconService05.vue'
import IconService06 from '@/components/svg/IconService06.vue'
import IconSmile from '@/components/svg/IconSmile.vue'
import { Pagination } from '@/components/board'
import subscrList from '@/pages/mypage/components/subscrList.vue'

const router = useRouter()

const payOwnList = ref(null)
const tabType = ref('ING')

const currentPoints = ref(0)

let totalElements = ref(0)
let totalPages = ref(0)
const totalCount = ref(0)

//검색조건
let paramData = ref({
  page: 1,
  size: 15,
})

//내포인트
//TODO: 보유 포인트
const getPoint = async () => {
  const params = {}
  const data = await ownPoint(params)
  currentPoints.value = data
}

//TODO: 목록
const payList = async (tabTypeVal) => {
  tabType.value = tabTypeVal
  let params = null
  params = {
    page: paramData.value.page,
    size: 5,
  }
  const urlParams = new URLSearchParams(params)
  if (tabTypeVal === 'ING') {
    urlParams.append('productType', 'SUBSCRIPTION')
    urlParams.append('productType', 'PLAN_TICKET')
    urlParams.append('isExpired', false)
  } else if (tabTypeVal === 'FINISH') {
    urlParams.append('productType', 'SUBSCRIPTION')
    urlParams.append('productType', 'PLAN_TICKET')
    urlParams.append('isExpired', true)
  } else if (tabTypeVal === 'POINT') {
    urlParams.append('productType', 'POINT')
    getPoint()
  }

  const queryString = urlParams.toString()
  const data = await paymentOwn(queryString)
  payOwnList.value = data?.content
  totalElements.value = data.totalElements
  totalPages.value = data.totalPages
}

onMounted(async () => {
  payList('ING')
})

const goPayView = (orderId, productType) => {
  router.push(
    `/mypage/subscrPaymentView/${orderId}?tabType=${tabType.value}&productType=${productType}`,
  )
}

const goLink = (url) => {
  router.push(`${url}`)
}

// 숫자에 천 단위 구분 쉼표를 추가하는 함수
const formatNumber = (num) => {
  if (num < 1000) return num
  return new Intl.NumberFormat().format(num)
}

const changePage = (page) => {
  paramData.value.page = page
  payList(tabType.value)
  //store.dispatch('saveCurrentEssayPage', page);
}
</script>

<template>
  <div class="my-info-wrap">
    <div class="my-tit-box com-bg-my-box h-small">
      <div class="tit">
        이용중인 서비스 <span>상세화면에서 결제 및 사용 내역과 영수증을 확인하실 수 있습니다.</span>
      </div>
      <div class="path">
        <ul>
          <li>홈</li>
          <li>마이페이지</li>
          <li>구독</li>
          <li>이용중인 서비스</li>
        </ul>
      </div>
    </div>
    <div class="myinfo-box com-bg-my-box">
      <!-- {{ payOwnList }} -->
      <div class="my-tab-st1-box">
        <div class="btn-box">
          <button class="tab-btn" :class="{ active: tabType === 'ING' }" @click="payList('ING')">
            이용중인 서비스
          </button>
          <button
            class="tab-btn"
            :class="{ active: tabType === 'FINISH' }"
            @click="payList('FINISH')"
          >
            만료된 서비스
          </button>
          <button
            class="tab-btn"
            :class="{ active: tabType === 'POINT' }"
            @click="payList('POINT')"
          >
            포인트 내역
          </button>
        </div>
        <div class="point-txt-box" v-if="tabType === 'POINT'">
          <span>보유 낭만 포인트</span>
          <span class="point-num-box">
            <i class="icon-point"></i>
            <span>{{ formatNumber(currentPoints) }}p</span>
          </span>
        </div>
      </div>
      <!-- //TODO 있을 경우 -->
      <div class="" v-if="payOwnList">
        <!-- <div class="my-pay-tit-box gap-xxl">
          <div class="btn-box">
            <VBtn class="sBtn line h38" variant="outlined" @click="payList('PLAN_TICKET')"
              >월 이용권 상품 보기</VBtn
            >
            <VBtn class="sBtn h38" @click="payList('SUBSCRIPTION')">정기구독 상품 보기</VBtn> 
            <VBtn
              class="sBtn line h38"
              @click="goLink('/mypage/subscrRepoMonth')"
              variant="outlined"
              >월 이용권 상품 보기</VBtn
            >
            <VBtn class="sBtn h38" @click="goLink('/mypage/subscrRepo')">정기구독 상품 보기</VBtn> 
          </div>
        </div> -->
        <div class="my-pay-list-box">
          <subscrList :pr-pay-own-list="payOwnList" :tabType="tabType" />
        </div>
        <Pagination
          v-if="totalElements > 0"
          :total-pages="totalPages"
          :current-page="paramData.page"
          @page-changed="changePage"
        />
      </div>
      <!-- //TODO 없을 경우 -->
      <div class="" v-else>
        <div class="pay-none-box">
          <div class="none-title-box">
            <div class="icon-box">
              <IconSmile />
            </div>
            <div class="txt1">현재 이용중인 서비스가 없습니다.</div>
            <div class="txt2">상품을 구매하고 다양한 혜택을 누려보세요!</div>
          </div>
          <div class="btn-box">
            <VBtn
              class="sBtn line h38"
              @click="goLink('/mypage/subscrRepoMonth')"
              variant="outlined"
              >월 이용권 상품 보기</VBtn
            >
            <VBtn class="sBtn h38" @click="goLink('/mypage/subscrRepo')">정기구독 상품 보기</VBtn>
          </div>
          <div class="pay-line-box"></div>
          <div class="none-exp-box">
            <div class="tit-box">이용권 전용 혜택 💌</div>
            <div class="exp-dl-box">
              <dl>
                <dt><IconService01 /></dt>
                <dd>
                  <div class="tit">보안 저장소</div>
                  <div class="txt">
                    사주 데이터를 안전하게 보관하는 보안 저장소를 이용하세요! <br />데이터는 최신
                    암호화 기술로 보호됩니다.
                  </div>
                </dd>
              </dl>
              <dl>
                <dt><IconService02 /></dt>
                <dd>
                  <div class="tit">사주 저장</div>
                  <div class="txt">
                    요금제에 따라 기본 30건, 100건, 200건까지 저장할 수 있으며, <br />별도의 무제한
                    요금제를 통해 제한 없이 저장 가능합니다.
                  </div>
                </dd>
              </dl>
              <dl>
                <dt><IconService03 /></dt>
                <dd>
                  <div class="tit">그룹 관리</div>
                  <div class="txt">
                    그룹을 추가하고 순서를 자유롭게 변경할 수 있습니다. <br />즐겨찾기를 설정하여
                    원하는 그룹을 손쉽게 관리하세요!
                  </div>
                </dd>
              </dl>
              <dl>
                <dt><IconService04 /></dt>
                <dd>
                  <div class="tit">메모 작성 및 관리</div>
                  <div class="txt">
                    메모를 등록하고 효율적으로 사주관리를 해보세요! <br />요금제를 이용하면 무제한
                    메모 등록 가능해요! (기본 1개 제공)
                  </div>
                </dd>
              </dl>
              <dl>
                <dt><IconService05 /></dt>
                <dd>
                  <div class="tit">인쇄하기</div>
                  <div class="txt">
                    사주결과 출력물을 다양하게 선택해 인쇄할 수 있습니다!<br />
                    기본 A타입 외에 B,C,D 타입도 제공합니다. (유료)
                  </div>
                </dd>
              </dl>
              <dl>
                <dt><IconService06 /></dt>
                <dd>
                  <div class="tit">공유하기</div>
                  <div class="txt">
                    사주 내용을 텍스트로 복사하여 쉽게 공유할 수 있습니다!<br />
                    SNS(카카오톡)으로 간편하게 사주를 전송할 수 있습니다.
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
