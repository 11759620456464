<script setup>
import { nextTick, onMounted } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'

import { DEV_MODE } from '@/main'
import { dailyPersonalData, monthlyLuckData } from './tempData'

/** API */
import { fastSearch } from '@/api/Eoway'
import { getDetail, getGrouplist, getGroupUserlist } from '@/api/UserGroup'

/** composables */
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'
import { useCalendar, useCalendarFilter, useGesture } from '@/composables'

/** 컴포넌트 */
import FullCalendar from '@fullcalendar/vue3'
import SajuClockPopup from '@/components/calendar/SajuClockPopup.vue'
import ComLatest from '@/components/manse/ComLatest.vue'
import { VNavigationDrawer } from 'vuetify/lib/components/index.mjs'
import TodayLuckInnerView from '@/components/calendar/TodayLuckInnerView.vue'
import CalendarSidebar from '@/components/calendar/CalendarSidebar.vue'
import CalendarHeader from '@/components/calendar/CalendarHeader.vue'
import PopMySaju from '@/components/calendar/PopMySaju.vue'
import AlertComponent from '@/pages/manse/components/Alert.vue'
import ConfirmComponent from '@/pages/manse/components/Confirm.vue'
import SubscribeInfo from '@/components/calendar/SubscriptionInfo.vue'
import PointConfirm from '@/components/calendar/PointConfirm.vue'
import { getMySajuAPI, getMySajuGroupAPI, purchaseDailyFortuneAPI, setMySajuAPI } from '@/api/calendarApi'

definePage({ meta: { layout: 'manse' } })

const store = useStore()
const userId = ref(null)
userId.value = store.getters['user/id']

const router = useRouter();

const { getFiveClass, getGanjiColorFive, ...five } = fiveMixin();
const { ...useFunc } = useFunctions();

/** 로딩 인디케이터 상태 */
const isLoading = ref(false)
/** '내 사주' 로딩 상태 */
const isMySajuLoading = ref(false);
/** '사주시계' 팝업 상태 */
const isPopSajuClock = ref(false);
/** '사주시계' 참조 */
const currentClockRef = ref(null);
/** alert 참조 */
const dialogAlert = ref(null);
/** confirmAlert 참조 */
const dialogConfirm = ref(null);
/** 포인트 결제 Alert 참조 */
const pointConfirm = ref(null);
const dialogTitle = ref('');
const dialogTitle2 = ref('');
const dialogText = ref('');
const dialogNextUrl = ref(null);

/** 열려있는 팝업/모달 상태 확인 */
const isAnyPopupOpen = computed(() => 
  isPopSajuClock.value
  || isPopMySajuOpen.value
  || isInnerViewOpen.value
  || isLoading.value
);

/** 일진 보기*/
const isTodayLuckChecked = ref(false)

const isInnerViewOpen = ref(false);

const sajuParamData = ref({
  year: '',
  month: '',
  day: '',
  hour: '',
  min: '',
  gender: null,
  userName: '',
  isLunar: false,
  lunar: null,
  isLeapYear: null,
  bornAreaId: null,
  birth_time: null,
  birth: null,
  birthTime: null,
  unknownTime: null,
  option1: true,
  option2: true,
  option3: false,
  option4: true,
  addressTxt: null,
  lang: 'CHN',
  langYN: 'N',
  userGroupId: null,
  shortly: false,
  blCheck: 'N',
});

const sajuData = ref({});
const inputdata = ref(null);
const transdata = ref(null);
const birthData = ref(null);
const mySajuList = ref({});

const selectedDateInfo = ref({})
const dailyLuckData = ref({});

/** 날짜 클릭 시 이너뷰 열기 */
const openInnerView = ({info, actionType}) => {
  if (!userId.value) return;
  if (info) selectedDateInfo.value = info;
  if (isInnerViewOpen) {    
    const dataKey = moment(info.date).format('YYYYMMDD');
    const yyyymmdd = moment(info.date).format('YYYY-MM-DD');
    dailyLuckData.value = monthlyLuckData.value[dataKey];
    // isInnerViewOpen.value = true;

    if (actionType === 'today') {
      monthlyLuckData.value[dataKey].isOpened = true;
      isInnerViewOpen.value = true;
    } else {
      showPointConfirm({
        title: moment(info.date).format('YYYY년 MM월 DD일'),
        subtitle: '운세를 구매 하시겠습니까?',
        point: pointDailyLuck.value,
        confirmText: '구매',
        onConfirm: async () => await chargePoint({yyyymmdd, dataKey}),
      })
    }
  } // 이너뷰 열기
};

/** 이너뷰 닫기 */
// const closeInnerView = () => {
//   if (isInnerViewOpen) isInnerViewOpen.value = false;
// };

const dialogSubtitle = ref('')

/** 결제될 포인트 */
const pointDailyLuck = ref('200');
const displayPoint = ref('');
const showPointConfirm = ({title, subtitle, point, confirmText, onConfirm = () => {}}) => {
  dialogTitle.value = title;
  dialogSubtitle.value = subtitle;
  dialogText.value = confirmText;
  displayPoint.value = point;
  dialogNextUrl.value = onConfirm;
  pointConfirm.value.openConfirmDialog();
}

const purchaseDailyFortune = async ({yyyymmdd, purchaseType}) => {  
  const [year, month, day] = yyyymmdd.split('-');
  const params = {
    year, // 필수
    month,  // 필수
    day, // purchaseType: "DAY" 일 때
    purchaseType, // "DAY", "MONTH" (필수)
  }

  try {
    const result = await purchaseDailyFortuneAPI(params);
    if (result) calendarApi.value.render();
    
  } catch (e) {
    
  }
}

const chargePoint = async ({yyyymmdd, dataKey}) => {  
  // 결제 로직
  await purchaseDailyFortune({yyyymmdd, purchaseType: 'DAY'});

  const [year, month, day] = yyyymmdd.split('-');
  // 컨텐츠 구매 처리
  await getDailyFortuneCalendar(`${year} ${month}`);
  // monthlyLuckData.value[dataKey].isOpened = true;

  // 컨텐츠 열기
  isInnerViewOpen.value = true;

  calendarApi.value.render();
}

/** 확인 알림창 표시 */
const showDialogAlert = ({title, text = '', nextUrl = ''}) => {
  dialogTitle.value = title;
  dialogText.value = text;
  dialogAlert.value.openAlertDialog();
};

/** 확인/취소 알림창 표시 */
const showDialogConfirm = ({title, title2 = '', text = '', onConfirm = () => {}}) => {
  dialogTitle.value = title
  dialogTitle2.value = title2
  dialogText.value = text
  dialogNextUrl.value = onConfirm // 함수로 전달
  dialogConfirm.value.openConfirmDialog()
};

const showLoginConfirm = () => {
  showDialogConfirm({
    title: '로그인 후 이용가능합니다.',
    text: '로그인',
    onConfirm: () => router.push('/auth/login')
  });
};

/** 달력 필터 */
const {
  isLunarChecked,
  isSeasonChecked,
  isHolidayChecked,
  isKorChecked,
  isColorChecked
} = useCalendarFilter({isKorCheckedDefault: true});

/** 달력 생성 */
const {
  calendarApi,
  calendarOptions,
  refCalendar,
  viewTitle,
  selectedDate,
  selectedData,
  firstSeason,
  firstSeasonMonth,
  prev,
  next,
  isToday,
  changeViewTitle,
  closeInnerView,
  setPrevSelectedDate,
  getDailyFortuneCalendar,
} = useCalendar({
  isLunarChecked,
  isColorChecked,
  isSeasonChecked,
  isKorChecked,
  isHolidayChecked,
  calendarType: 'web',
  isInnerViewOpen,
  isTodayLuckChecked,
  userId,
  sajuData,
  pointDailyLuck,
  openInnerView,
  showDialogAlert,
  showLoginConfirm,
});

/** 통합 제스처 핸들러 */
const {
  handleStart,
  handleMove,
  handleEnd
} = useGesture({
  onSwipeLeft: next,
  onSwipeRight: prev,
});

/** 역학달력 옵션 */
const toggleOptions = [
  { id: 'lunar', label: '음력', value: isLunarChecked },
  { id: 'kor_display', label: '한글표기', value: isKorChecked },
  { id: 'season', label: '절기', value: isSeasonChecked },
  { id: 'color_display', label: '컬러표시', value: isColorChecked },
  { id: 'holiday', label: '공휴일', value: isHolidayChecked },
];

onMounted(async () => {
  if (DEV_MODE) {

    if (userId.value) {
      getMySaju();
    }
  } else {
    router.push('/main')
  }
});

const getSajuParams = async (id) => {
  if (!id) return;

  try {
    const result = await getDetail(id);

    const dateTime = result.integrationBirth.split('T');
    const [year, month, day] = dateTime[0].split('-');
    const [hour, min] = dateTime[1]?.split(':');    
    
    sajuParamData.value.year = year;
    sajuParamData.value.month = month.padStart(2, '0');
    sajuParamData.value.day = day.padStart(2, '0');
    sajuParamData.value.hour = hour.padStart(2, '0');
    sajuParamData.value.min = min.padStart(2, '0');
    sajuParamData.value.gender = result.gender === 'MEN' ? 1 : 0;
    sajuParamData.value.userName = result.guestUserName;
    sajuParamData.value.isLunar = result.isLuna === null ? false : result.isLuna;
    sajuParamData.value.lunar = result.lunar;
    sajuParamData.value.isLeapYear = result.isLeapYear;
    sajuParamData.value.bornAreaId = result.bornAreaId;
    sajuParamData.value.birthTime = result.birthTime;
    sajuParamData.value.unknownTime = result.unknownTime
    sajuParamData.value.option1 = result.option1;
    sajuParamData.value.option2 = result.option2;
    sajuParamData.value.option3 = result.option3;
    sajuParamData.value.option4 = result.option4;
    sajuParamData.value.option5 = result.option5;
    sajuParamData.value.addressTxt = result.bornArea;
    // sajuParamData.value.lang = result.lang
    // sajuParamData.value.langYN = result.langYN
    // sajuParamData.value.userGroupId = result.userGroupId
  } catch (e) {
    DEV_MODE && console.error('사주정보 조회 오류:', e);
  };
};

const padNumber = (num) => num.toString().padStart(2, '0');

const convertManseForceParams = () => {
  const { year, month, day, hour, min } = sajuParamData.value;

  sajuParamData.value.birth = `${year}-${padNumber(month)}-${padNumber(day)}`;  
  
  if (sajuParamData.value.birthTime === '25:00') {
    sajuParamData.value.unknownTime = 'Y';
  } else {
    sajuParamData.value.birthTime = `${padNumber(hour)}:${padNumber(min)}`;
  }
  if (!sajuParamData.value.unknownTime) sajuParamData.value.unknownTime = 'N';
  if (!sajuParamData.value.isLeapYear) sajuParamData.value.isLeapYear = false;
  if (sajuParamData.value.bornAreaId === null) delete sajuParamData.value.bornAreaId;
}

const getManseForce = async () => {
  convertManseForceParams();

  try {    
    const result = await fastSearch(sajuParamData.value);    
    sajuData.value = result;    
    inputdata.value = result.inputdata;
    transdata.value = result.transdata;
    birthData.value = result.birthganji;
  } catch (e) {
    DEV_MODE && console.error('만세력 조회 오류:', e);
  }
}

/** TODAY 버튼 클릭 */
const today = () => {
  refCalendar.value.getApi().gotoDate(new Date())
  setPrevSelectedDate()
  selectedDate.value = moment().format('YYYY-MM-DD')
  changeViewTitle()
}
/** 사주시계 팝업  */
const setSajuClock = (state) => (isPopSajuClock.value = state)

const createFiveElements = (fiveData) => {
  if (!fiveData) return;
}

const isPopMySajuOpen = ref(false);
const selectedMySajuId = ref(null);
const openPopMySaju = async () => {
  if (userId.value) {
    await getMySajuList();
    isPopMySajuOpen.value = true;
  } else {
    showLoginConfirm();
    return;
  }
}
const closePopMySaju = () => isPopMySajuOpen.value = false;
const selectMySaju = async (id) => {
  selectedMySajuId.value = id;
}

/** '내 사주 선택' 팝업에서 '완료' 버튼 클릭 */
const confirmSelectSaju = () => {
  showDialogConfirm({
    title: '선택하시면 변경이 불가능합니다.',
    title2: '등록하시겠습니까?',
    text: '등록',
    onConfirm: async () => {
      if (selectedMySajuId) {
        await setMySaju();
        closePopMySaju();
        calendarApi.value.render();
      }
    }
  })
}

/** '내 사주' 그룹 목록 불러오기 */
const getMySajuList = async () => {
  try {
    const result = await getMySajuGroupAPI({});
    mySajuList.value = result;
  } catch (e) {
    
  }
}

/** '내 사주' 등록 */
const setMySaju = async () => {
  const params = {
    guestUserId: selectedMySajuId.value,
    serviceCategory: "DAILY_FORTUNE_CALENDAR",
  }

  try {
    await setMySajuAPI(params);
    getMySaju();
  } catch (e) {
    
  }
}

/** '내 사주' 정보 조회 */
const getMySaju = async () => {
  isMySajuLoading.value = true;
  const params = {
    serviceCategory: "DAILY_FORTUNE_CALENDAR",
  }

  try {
    const result = await getMySajuAPI(params);

    if (result) {
      const guestUserId = result.content[0]['guest_user_id'];
      await getSajuParams(guestUserId);
      await getManseForce();
    }
  } catch (e) {

  } finally {
    isMySajuLoading.value = false;
  }
}

/** '내 사주 등록하기' 클릭 */
const goManse = () => {
  // '내 사주' 그룹에 등록된 사주가 없을 때
  router.push('/manse');
}

const isOpenSajubox = ref(true);

watch(() => isInnerViewOpen.value, async (newState) => {
  await nextTick()

  const getCenterCoordinates = (element) => {
    const rect = element.getBoundingClientRect();
    const containerRect = document.querySelector(".saju-container").getBoundingClientRect();
    return {
      x: rect.left + rect.width / 2 - containerRect.left,
      y: rect.top + rect.height / 2 - containerRect.top,
    };
  };

  const icon1 = document.getElementById("ts1");
  const icon2 = document.getElementById("ts2");
  const icon3 = document.getElementById("ts3");

  const line1 = document.getElementById("line1");
  const line2 = document.getElementById("line2");

  if (icon1 && icon2 && line1) {
    const coord1 = getCenterCoordinates(icon1);
    const coord2 = getCenterCoordinates(icon2);

    line1.setAttribute("x1", coord1.x + 22);
    line1.setAttribute("y1", coord1.y);
    line1.setAttribute("x2", coord2.x - 22);
    line1.setAttribute("y2", coord2.y);
  }

  if (icon1 && icon3 && line2) {
    const coord1 = getCenterCoordinates(icon1);
    const coord3 = getCenterCoordinates(icon3);

    line2.setAttribute("x1", coord1.x + 22);
    line2.setAttribute("y1", coord1.y);
    line2.setAttribute("x2", coord3.x - 22);
    line2.setAttribute("y2", coord3.y);
  }
})

/** 선택 가능한 카테고리별 운세 최대 개수 */
const selectableCount = 3;
/** 카테고리별 운세 활성화 여부 */
const luckButtonDisabled = computed(() => {
  const activeCount = Object.values(luckStates).filter((state) => state).length;
  return activeCount >= selectableCount;
})

const luckStates = reactive({
  studyLuck: false,
  careerLuck: false,
  wealthLuck: false,
  loveLuck: false,
  healthLuck: false,
  familyLuck: false,
  mobilityLuck: false,
  socialLuck: false,
  honorLuck: false,
  contributionLuck: false,
});

const luckList = reactive([
  { key: "studyLuck", label: "학습운", iconClass: "study" },
  { key: "careerLuck", label: "직업운", iconClass: "career" },
  { key: "wealthLuck", label: "재물운", iconClass: "wealth" },
  { key: "loveLuck", label: "애정운", iconClass: "love" },
  { key: "healthLuck", label: "건강운", iconClass: "health" },
  { key: "familyLuck", label: "가족운", iconClass: "family" },
  { key: "mobilityLuck", label: "이동운", iconClass: "mobility" },
  { key: "socialLuck", label: "인간관계운", iconClass: "social" },
  { key: "honorLuck", label: "명예운", iconClass: "honor" },
  { key: "contributionLuck", label: "사회공헌운", iconClass: "contribution" },
]);

const selectedLuckList = computed(() => {
  return luckList.filter((luck) => luckStates[luck.key]);
});

const toggleLuck = (key) => {  
  luckStates[key] = !luckStates[key];
};

let scrollPosition = 0
const open = () => {
  scrollPosition = window.scrollY // 현재 스크롤 위치 저장
  document.body.style.position = 'fixed'
  document.body.style.top = `-${scrollPosition}px` // 스크롤 위치 고정
  document.body.style.width = '100%' // 가로 스크롤 방지
}
const close = () => {
  document.body.style.position = '' // 원래 상태로 복구
  document.body.style.top = ''
  window.scrollTo(0, scrollPosition) // 저장한 스크롤 위치로 복구
}
// 팝업/모달 열렸을 때, 스크롤 방지
watch(isAnyPopupOpen, (isOpen) => {
  if (isOpen) open()
  else close()
});


// 천간의 음/양
const yinYangSky = {
  갑: '양', 을: '음',
  병: '양', 정: '음',
  무: '양', 기: '음',
  경: '양', 신: '음',
  임: '양', 계: '음',
  甲: '양', 乙: '음', // 한자 추가
  丙: '양', 丁: '음',
  戊: '양', 己: '음',
  庚: '양', 辛: '음',
  壬: '양', 癸: '음',
};

// 지지의 음양
const yinYangEarth = {
  자: '양', 축: '음',
  인: '양', 묘: '음',
  진: '양', 사: '음',
  오: '양', 미: '음',
  신: '양', 유: '음',
  술: '양', 해: '음',
  子: '양', 丑: '음', // 한자 추가
  寅: '양', 卯: '음',
  辰: '양', 巳: '음',
  午: '양', 未: '음',
  申: '양', 酉: '음',
  戌: '양', 亥: '음',
};
const createYinYangCount = (handayju) => {
    if (!sajuData) return;
    const sky = sajuData.value.birthganji?.sky;
    const earth = sajuData.value.birthganji?.earth;
    // 음/양 카운트
    let yinCount = 0;
    let yangCount = 0;
    
    if (!sky || !earth) return `<div></div>`;
    
    // 천간 (sky)
    for (const key of ['year', 'month', 'day', 'hour']) {
      const value = sky[key]?.value;
      if (value) {
        if (yinYangSky[value] === '양') yangCount++;
        else if (yinYangSky[value] === '음') yinCount++;
      }
    }

    // 지지 (earth)
    for (const key of ['year', 'month', 'day', 'hour']) {
      const value = earth[key]?.value;
      if (value) {
        if (yinYangEarth[value] === '양') yangCount++;
        else if (yinYangEarth[value] === '음') yinCount++;
      }
    }

    if (handayju && handayju.length === 2) {      
      const [sky, earth] = handayju.split('');
      if (yinYangSky[sky] === '양') yangCount++;
      else if (yinYangSky[sky] === '음') yinCount ++
      if (yinYangEarth[earth] === '양') yangCount++;
      else if (yinYangEarth[earth] === '음') yinCount++;
    }
    
    return {
      yinCount,
      yangCount,
    }
  }
</script>

<template>
  <div class="calendar-wrap v2">
    <SubscribeInfo />
    <VNavigationDrawer
      v-if="isInnerViewOpen && DEV_MODE"
      v-model="isInnerViewOpen"
      app
      temporary
      location="right"
      width="auto"
    >
      <div class="drawer-container">
        <div class="drawer-header">
          <div class="title">
            {{ moment(selectedDateInfo?.date).format('YYYY년 MM월 DD일') }}
            <div class="day-ganji">
              <div class="ganji-txt" :class="getFiveClass(getGanjiColorFive(dailyLuckData['cg_hj'].split('')[0]))">{{ dailyLuckData['cg_hj'].split('')[0] }}</div>
              <div class="ganji-txt" :class="getFiveClass(getGanjiColorFive(dailyLuckData['cg_hj'].split('')[1]))">{{ dailyLuckData['cg_hj'].split('')[1] }}</div>일
            </div>
          </div>
          <button class="close" @click="closeInnerView"></button>
        </div>

        <div class="drawer-body">
          <div class="drawer-section">
            <div class="drawer-card-box">
              <div class="drawer-card clear">
                {{ five.getGanjiDescription(`${dailyLuckData['cg_hj']}`) }}
              </div>
              <div class="drawer-card clear">
                <i :class="`rate${dailyLuckData.emoji.level}`"></i>
                <div>에너지 지수 30%</div>
              </div>
            </div>

            <div class="drawer-content">
              <div class="title">유비무환(有備無患)</div>
              <div>
                "준비가 있으면 근심이 없다"는 뜻으로,<br>에너지를 미리 비축해 두는 것의 중요성을 의미합니다.
              </div>
              <div>
                "쉬어 가는 것도 하나의 능력입니다.<br>잠시 멈추고 숨을 고르며 다시 힘을 모아 나아가세요.<br>당신의 에너지는 곧 빛날 거예요."
              </div>
            </div>

            <div class="drawer-content">
              <div class="title">
                <div class="ganji-txt" :class="getFiveClass(getGanjiColorFive(sajuData.birthganji.sky.day.value))">
                  {{ sajuData.birthganji.sky.day.value }}
                </div>
                <div class="ganji-txt" :class="getFiveClass(getGanjiColorFive(sajuData.birthganji.earth.day.value))">
                  {{ sajuData.birthganji.earth.day.value }}
                </div>
                일주가&nbsp;
                <div class="ganji-txt" :class="getFiveClass(getGanjiColorFive(dailyLuckData['cg_hj'].split('')[0]))">
                  {{ dailyLuckData['cg_hj'].split('')[0] }}
                </div>
                <div class="ganji-txt" :class="getFiveClass(getGanjiColorFive(dailyLuckData['cg_hj'].split('')[1]))">
                  {{ dailyLuckData['cg_hj'].split('')[1] }}
                </div>
                일을 만나면?
              </div>
              <div class="drawer-card">
                <div class="card-subtitle">나의 오행</div>
                <div class="saju-row">
                  <div class="five">
                    <div class="value">
                      <div class="txt five03 ganji-txt">木</div>{{ birthData.five.five1.num }}
                    </div>
                    <div class="value">
                      <div class="txt five01 ganji-txt">火</div>{{ birthData.five.five2.num }}
                    </div>
                    <div class="value">
                      <div class="txt five05 ganji-txt">土</div>{{ birthData.five.five3.num }}
                    </div>
                    <div class="value">
                      <div class="txt five04 ganji-txt">金</div>{{ birthData.five.five4.num }}
                    </div>
                    <div class="value">
                      <div class="txt five02 ganji-txt">水</div>{{ birthData.five.five5.num }}
                    </div>
                  </div>
                  <div class="value">
                    <div class="txt yang">양 4</div> /
                    <div class="txt yin">음 4</div>
                  </div>
                </div>
                <div class="card-title">{{ dailyLuckData['cg_hj'].split('')[0] }}{{ dailyLuckData['cg_hj'].split('')[1] }}일을 만난 나의 오행</div>
                <div class="saju-row">
                  <div class="five">
                    <div class="value">
                      <div class="txt five03 ganji-txt">木</div>{{ dailyLuckData['fe_cnt']['목'] }}
                    </div>
                    <div class="value">
                      <div class="txt five01 ganji-txt">火</div>{{ dailyLuckData['fe_cnt']['화'] }}
                    </div>
                    <div class="value">
                      <div class="txt five05 ganji-txt">土</div>{{ dailyLuckData['fe_cnt']['토'] }}
                    </div>
                    <div class="value">
                      <div class="txt five04 ganji-txt">金</div>{{ dailyLuckData['fe_cnt']['금'] }}
                    </div>
                    <div class="value">
                      <div class="txt five02 ganji-txt">水</div>{{ dailyLuckData['fe_cnt']['수'] }}
                    </div>
                  </div>
                  <div class="value">
                    <div class="txt yang">양 {{ createYinYangCount(dailyLuckData['cg_hg']).yangCount }}</div> /
                    <div class="txt yin">음 {{ createYinYangCount(dailyLuckData['cg_hg']).yinCount }}</div>
                  </div>
                </div>
              </div>

              <div class="drawer-card">
                <div class="title-row">
                  <div class="title-box">
                    <div class="card-title">오늘의 십성</div>
                    <div class="card-subtitle">{{ dailyLuckData['cg_hj'] }}일을 만난 나, 오늘의 십성은?</div>
                  </div>
                  <div class="data-box">{{ dailyLuckData['sfb_at']['cc'] }} / {{ dailyLuckData['sfb_at']['gg'] }}</div>
                </div>

                <div class="card-content">
                  <div class="saju-container">
                    <svg class="lines">
                      <line id="line1" x1="0" y1="0" x2="0" y2="0" stroke-width="1px" stroke="var(--orange-300-main, #EB4C10)" />
                      <line id="line2" x1="0" y1="0" x2="0" y2="0" stroke-width="1px" stroke="var(--orange-300-main, #EB4C10)" />
                    </svg>
                    <div class="saju-box">
                      <div class="label">일간ㆍ{{ five.getYinYang(sajuData.birthganji.sky.day.value, 'sky') }}</div>
                      <div class="saju-icon" id="ts1" :class="five.getColorFive(sajuData.birthganji.sky.day.value)">
                        <div class="saju-text">{{ sajuData.birthganji.sky.day.value }}</div>
                      </div>
                    </div>
                    <div class="saju-box">
                      <div class="label">{{ dailyLuckData['sfb_at']['cc'] }}ㆍ{{ five.getYinYang(dailyLuckData['cg_hj'].split('')[0], 'sky') }}</div>
                      <div class="saju-icon" id="ts2" :class="five.getColorFive(dailyLuckData['cg_hj'].split('')[0])">
                        <div class="saju-text">{{ dailyLuckData['cg_hj'].split('')[0] }}</div>
                      </div>
                      <div class="saju-icon" id="ts3" :class="five.getColorFive(dailyLuckData['cg_hj'].split('')[1])">
                        <div class="saju-text">{{ dailyLuckData['cg_hj'].split('')[1] }}</div>
                      </div>
                      <div class="label">{{ dailyLuckData['sfb_at']['gg'] }}ㆍ{{ five.getYinYang(dailyLuckData['cg_hj'].split('')[1], 'earth') }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="drawer-card">
                <div class="title-row">
                  <div class="title-box">
                    <div class="card-title">오늘의 지장간</div>
                    <div class="card-subtitle">나와 오늘의 지장간?</div>
                  </div>
                  <div class="data-box">
                    <div 
                      class="ganji-txt"
                      :class="five.getColorFive(item)"
                      v-for="(item, index) in dailyLuckData['ggj_at']['ggj']" 
                      :key="index"
                    >
                      {{ item }}
                    </div>
                  </div>
                </div>

                <div class="card-content">
                  <div class="saju-container">
                    <div class="saju-box">
                      <div class="saju-icon" :class="five.getColorFive(sajuData.birthganji.sky.day.value)">
                        <div class="saju-text">{{ sajuData.birthganji.sky.day.value }}</div>
                      </div>
                      <div class="saju-icon" :class="five.getColorFive(sajuData.birthganji.earth.day.value)">
                        <div class="saju-text">{{ sajuData.birthganji.earth.day.value }}</div>
                      </div>
                      <div class="label">경 신</div>
                    </div>
                    <div class="saju-box">
                      <div class="saju-icon txt-round" :class="five.getColorFive(dailyLuckData['cg_hj'].split('')[0])">
                        <div class="saju-text">{{ dailyLuckData['cg_hj'].split('')[0] }}</div>
                      </div>
                      <div class="saju-icon" :class="five.getColorFive(dailyLuckData['cg_hj'].split('')[1])">
                        <div class="saju-text">{{ dailyLuckData['cg_hj'].split('')[1] }}</div>
                      </div>
                      <div class="label">
                        <div 
                          v-for="(item, index) in dailyLuckData['ggj_at']['ggj']" 
                          :key="index"
                        >
                          {{ item }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="drawer-divider"></div>
          
          <div class="drawer-section">
            <div class="drawer-content">
              <div class="title">카테고리 별 운세도 확인해 보세요!</div>
              <div class="button-container">
                <button
                  v-for="luck in luckList"
                  :key="luck.key"
                  class="luck-button"
                  :class="{ active: luckStates[luck.key], disabled: luckButtonDisabled && !luckStates[luck.key] }"
                  :disabled="luckButtonDisabled && !luckStates[luck.key]"
                  @click="toggleLuck(luck.key)"
                >
                  <i :class="luck.iconClass"></i>
                  <div class="label">{{ luck.label }}</div>
                </button>
              </div>
            </div>

            <div 
              v-if="selectedLuckList && selectedLuckList.length === 0"
              class="empty-content"
            >
              <div class="wrapper">
                <div class="title">매일 원하는 3개 주제의 일진을<br>선택해서 보실 수 있어요.</div>
                <div class="subtitle">추가 궁금한 주제는 낭만 포인트를 활용해서 보실 수 있습니다.</div>
              </div>
            </div>

            <div
              v-for="luck in selectedLuckList"
              :key="luck.key"
              class="drawer-content luck"
            >
              <div class="title">{{ luck.label }}</div>
              <div>{{ dailyPersonalData['생활종합'][luck.label] }}</div>
              <div>{{ dailyPersonalData['생활운세'][luck.label] }}</div>
              <div>{{ dailyPersonalData['생활추천'][luck.label] }}</div>
            </div>
          </div>
        </div>
      </div>
    </VNavigationDrawer>

    <div class="content-main mb-large">
      <section class="inner-base-calendar v2">
        <div class="calendar-sidebar-layout">
          <CalendarSidebar
            :isLoading="isMySajuLoading"
            :isTodayLuckChecked="isTodayLuckChecked"
            :toggleOptions="toggleOptions"
            :sajuData="sajuData"
            :isOpenSajubox="isOpenSajubox"
            @openTodayLuck="isTodayLuckChecked = true"
            @closeTodayLuck="isTodayLuckChecked = false"
            @openSajuBox="openPopMySaju"
            @closeSajuBox="isOpenSajubox = !isOpenSajubox"
          />

          <div class="calendar-main">
            <CalendarHeader
              :calendarType="'v2'"
              :title="viewTitle"
              :isToday="isToday()"
              :solarTerm="firstSeason"
              :solarTermMonth="firstSeasonMonth"
              @prev="prev"
              @next="next"
              @today="today"
              @clock="setSajuClock(true)"
            />

            <div class="sec-sch-body">
              <!-- <div class="quick-menu-box">
                <ComLatest @printclick="printManse" />
              </div> -->
              <v-row
                class="fill-height"
                @mousedown="handleStart"
                @mousemove="handleMove"
                @mouseup="handleEnd"
                @touchstart="handleStart"
                @touchmove="handleMove"
                @touchend="handleEnd"
              >
                <v-col>
                  <v-sheet height="700">
                    <FullCalendar
                      ref="refCalendar"
                      class="full-calendar"
                      :options="calendarOptions"
                    />
                  </v-sheet>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </section>
    </div>

    <SajuClockPopup
      v-if="isPopSajuClock"
      ref="currentClockRef"
      @closeClock="setSajuClock(false)"
    />

    <PopMySaju
      :visible="isPopMySajuOpen"
      :mySajuList="mySajuList"
      :selectedMySajuId="selectedMySajuId"
      @close="closePopMySaju"
      @route="goManse"
      @select="selectMySaju"
      @confirm="confirmSelectSaju"
    />

    <AlertComponent
      ref="dialogAlert"
      :title="dialogTitle"
      :text="dialogText"
    />

    <ConfirmComponent
      ref="dialogConfirm"
      :title="dialogTitle"
      :title2="dialogTitle2"
      :text="dialogText"
      :nextUrl="dialogNextUrl"
    />

    <PointConfirm
      ref="pointConfirm"
      :title="dialogTitle"
      :subtitle="dialogSubtitle"
      :point="displayPoint"
      :nextUrl="dialogNextUrl"
      :confirm-text="'구매'"
    />

    <div v-if="isLoading" class="loading-full-wrap">
      <div class="inner-box">
        <div ref="loadingContainer" class="loading-container">
          <v-progress-circular :size="50" :width="3" color="primary" indeterminate />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.v-navigation-drawer__scrim {
  background-color: rgba(0, 0, 0, 0) !important;
}
</style>

<style lang="scss">

.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent;
}
.v-calendar-monthly .v-calendar-weekly__head-weekday {
  height: 38px;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #23252e;
  font-size: 13px;
  font-weight: 500;
  border-bottom: 0.5px solid #e2e2e2;
}
.v-calendar-weekly__day-label {
  margin: 0 !important;
}
.v-calendar-weekly__day-label button.v-btn {
  display: none;
  margin: 0 !important;
}
.datePicker .v-card__text {
  padding: 20px !important;
}

.fc-day-sun {
  .fc-scrollgrid-sync-inner {
    .fc-col-header-cell-cushion {
      color: #eb4f5d;
    }
  }
}
.fc-day-sat {
  .fc-scrollgrid-sync-inner {
    .fc-col-header-cell-cushion {
      color: #5a6ef6;
    }
  }
}
.fc-col-header-cell {
  .fc-scrollgrid-sync-inner {
    .fc-col-header-cell-cushion {
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    a {
      color: inherit;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

.custom-day-cell.other {
  position: absolute;
  padding-top: 1px;
  top: 8px;
  left: 12px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  border-radius: 100%;
  cursor: pointer;
}
.custom-day-cell {
  position: absolute;
  padding-top: 1px;
  top: 4px;
  left: 9px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  border-radius: 100%;
  color: #23252e;
  cursor: pointer;
}
.custom-day-sunday,
.custom-day-holiday {
  color: #eb4f5d;
}
.custom-day-saturday {
  color: #5a6ef6;
}
.custom-day-highlight {
  background-color: #eb4c10;
  color: white;
}
.custom-day-selected {
  z-index: 100;
  background-color: #e1e1e1;
}
.custom-content-cell {
  height: 100%;
}
.fc-daygrid-event {
  box-shadow: none !important;
}
.fc-event::before,
.fc-event::after,
.custom-event-content::before,
.custom-event-content::after {
  // content: none !important;
  // display: none !important;
  background: transparent !important;
  box-shadow: none !important;
}
a.fc-event:focus {
  outline: none !important;
  box-shadow: none !important;
}

.custom-event-content {
  .custom-content-cell {
    .content-dayju {
      position: absolute;
      top: 4px;
      height: 28px;
      color: #23252e;

      .txt-chn {
        font-size: 18px;
      }
      
      .txt-kor {
        font-size: 14px;
        margin-left: 5px;
      }

      &.today-luck {
        color: var(--Gray-500, #949494);
        text-align: center;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.333px;
      }
    }
  }
}
.content-lunar {
  position: absolute;
  // top: 36px;
  top: 32%;
  left: 0;
  right: 0;
}
.content-holiday {
  font-size: 14px;
  position: absolute;
  left: 0;
  top: 54%;
  right: 0;
  margin-bottom: 0 !important;
}
.content-season {
  position: absolute;
  left: 0;
  top: 76%;
  right: 0;
}

table tbody tr {
  height: 3px;
}
</style>