<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'

import { DEV_MODE } from '@/main'

/** API */
import { fastSearch } from '@/api/Eoway'
import { getDetail, getGrouplist, getGroupUserlist } from '@/api/UserGroup'

/** composables */
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'
import { useCalendar, useCalendarFilter, useGesture } from '@/composables'

/** 컴포넌트 */
import FullCalendar from '@fullcalendar/vue3'
import ComLatest from '@/components/manse/ComLatest.vue'
import CalendarHeader from '@/components/calendar/CalendarHeader.vue'
import SajuClockPopup from '@/components/calendar/SajuClockPopup.vue'
import ToggleBox from '@/components/calendar/ToggleBox.vue'
import TodayLuckInnerView from '@/components/calendar/TodayLuckInnerView.vue'
import AlertComponent from '@/pages/manse/components/Alert.vue'
import CalendarSidebar from '@/components/calendar/CalendarSidebar.vue'

definePage({ meta: { layout: 'manse' } })

const store = useStore()
const userId = ref(null)
userId.value = store.getters['user/id']

const { getFiveClass, getGanjiColorFive, ...five } = fiveMixin();
const { ...useFunc } = useFunctions();

/** 로딩 인디케이터 상태 */
const isLoading = ref(false);
/** '사주시계' 팝업 상태 */
const isPopSajuClock = ref(false);
/** '사주시계' 참조 */
const currentClockRef = ref(null);
/** alert 참조 */
const dialogAlert = ref(null);
const dialogTitle = ref('');
const dialogText = ref('');

/** 일진 보기*/
const isTodayLuckChecked = ref(false);

const isInnerViewOpen = ref(false);

const sajuListParam = ref({
  birthStart: null,
  birthEnd: null,
  startDate: null,
  endDate: null,
  genderOption: 0,
  page: 1,
  size: 10,
  searchKeyWord: '',
  sortOption: null,
  userGroupId: null,
  activeOpt1: false,
  activeOpt2: false,
  activeOpt3: false,
  activeOpt4: false,
  fiveEles: [],
  gender: null,
  filterFlag: false,
  isFavorite: null,
})

const sajuParamData = ref({
  year: '',
  month: '',
  day: '',
  hour: '',
  min: '',
  gender: null,
  userName: '',
  isLunar: false,
  lunar: null,
  isLeapYear: null,
  bornAreaId: null,
  birth_time: null,
  birth: null,
  birthTime: null,
  unknownTime: null,
  option1: true,
  option2: true,
  option3: false,
  option4: true,
  addressTxt: null,
  lang: 'KOR',
  langYN: 'N',
  userGroupId: null,
  shortly: false,
  blCheck: 'N',
});

const sajuData = ref({});

const showDialogAlert = (title, text = '', nextUrl = '') => {
  dialogTitle.value = title;
  dialogText.value = text;
  dialogAlert.value.openAlertDialog();
};

const openInnerView = () => {
  showDialogAlert('일진달력 서비스 오픈 준비 중\n여러분의 일상에 새로운 의미를 더해드릴게요.\n많은 기대와 관심으로 조금만 기다려주세요!');
  return;
  
  if (!userId.value) return;
  if (info) selectedDateInfo.value = info;
  if (isInnerViewOpen) {
    const dataKey = moment(info.date).format('YYYYMMDD');
    dailyLuckData.value = monthlyLuckData[dataKey];
    isInnerViewOpen.value = true;
  } // 이너뷰 열기
}

/** 달력 필터 */
const {
  isLunarChecked,
  isSeasonChecked,
  isHolidayChecked,
  isKorChecked,
  isColorChecked
} = useCalendarFilter({isKorCheckedDefault: true});

/** 달력 생성 */
const {
  calendarOptions,
  refCalendar,
  viewTitle,
  selectedDate,
  selectedData,
  firstSeason,
  firstSeasonMonth,
  prev,
  next,
  isToday,
  changeViewTitle,
  closeInnerView,
  setPrevSelectedDate,
} = useCalendar({
  isLunarChecked,
  isColorChecked,
  isSeasonChecked,
  isKorChecked,
  isHolidayChecked,
  calendarType: 'web',
  isInnerViewOpen,
  isTodayLuckChecked,
  userId,
  sajuData,
  openInnerView,
});

/** 통합 제스처 핸들러 */
const {
  handleStart,
  handleMove,
  handleEnd
} = useGesture({
  onSwipeLeft: next,
  onSwipeRight: prev,
});

/** 역학달력 옵션 */
const toggleOptions = [
  { id: 'lunar', label: '음력', value: isLunarChecked },
  { id: 'kor_display', label: '한글표기', value: isKorChecked },
  { id: 'season', label: '절기', value: isSeasonChecked },
  { id: 'color_display', label: '컬러표시', value: isColorChecked },
  { id: 'holiday', label: '공휴일', value: isHolidayChecked },
];

onMounted(async () => {
  if (DEV_MODE) {
    const groupList = await getGrouplist({});
  
    if (groupList && groupList.length > 0) {
      sajuListParam.userGroupId = groupList[0].userGroupId;
      const result = await getGroupUserlist(sajuListParam);
      const mySajuList = result.content;
      if (mySajuList && mySajuList.length > 0) {
        const guestUserId = mySajuList[mySajuList.length - 1].guestUserId;
        if (guestUserId) {
          await getSajuParams(guestUserId);
          await getManseForce();
        }
      }
    }
  }
})

const getSajuParams = async (id) => {
  if (!id) return;

  try {
    const result = await getDetail(id);

    const dateTime = result.integrationBirth.split('T');
    const [year, month, day] = dateTime[0].split('-');
    const [hour, min] = dateTime[1]?.split(':');    
    
    sajuParamData.value.year = year;
    sajuParamData.value.month = month.padStart(2, '0');
    sajuParamData.value.day = day.padStart(2, '0');
    sajuParamData.value.hour = hour.padStart(2, '0');
    sajuParamData.value.min = min.padStart(2, '0');
    sajuParamData.value.gender = result.gender === 'MEN' ? 1 : 0;
    sajuParamData.value.userName = result.guestUserName;
    sajuParamData.value.isLunar = result.isLuna === null ? false : result.isLuna;
    sajuParamData.value.lunar = result.lunar;
    sajuParamData.value.isLeapYear = result.isLeapYear;
    sajuParamData.value.bornAreaId = result.bornAreaId;
    sajuParamData.value.birthTime = result.birthTime;
    sajuParamData.value.unknownTime = result.unknownTime
    sajuParamData.value.option1 = result.option1;
    sajuParamData.value.option2 = result.option2;
    sajuParamData.value.option3 = result.option3;
    sajuParamData.value.option4 = result.option4;
    sajuParamData.value.option5 = result.option5;
    sajuParamData.value.addressTxt = result.bornArea;
    // sajuParamData.value.lang = result.lang
    // sajuParamData.value.langYN = result.langYN
    // sajuParamData.value.userGroupId = result.userGroupId
  } catch (e) {
    DEV_MODE && console.error('사주정보 조회 오류:', e);
  };
};

const padNumber = (num) => num.toString().padStart(2, '0');

const convertManseForceParams = () => {
  const { year, month, day, hour, min } = sajuParamData.value;

  sajuParamData.value.birth = `${year}-${padNumber(month)}-${padNumber(day)}`;  
  
  if (sajuParamData.value.birthTime === '25:00') {
    sajuParamData.value.unknownTime = 'Y';
  } else {
    sajuParamData.value.birthTime = `${padNumber(hour)}:${padNumber(min)}`;
  }
  if (!sajuParamData.value.unknownTime) sajuParamData.value.unknownTime = 'N';
  if (!sajuParamData.value.isLeapYear) sajuParamData.value.isLeapYear = false;
  if (sajuParamData.value.bornAreaId === null) delete sajuParamData.value.bornAreaId;
}

const getManseForce = async () => {
  convertManseForceParams();

  try {    
    const result = await fastSearch(sajuParamData.value);    
    sajuData.value = result;
  } catch (e) {
    DEV_MODE && console.error('만세력 조회 오류:', e);
  }
}

/** TODAY 버튼 클릭 */
const today = () => {
  refCalendar.value.getApi().gotoDate(new Date())
  setPrevSelectedDate()
  selectedDate.value = moment().format('YYYY-MM-DD')
  changeViewTitle()
}

/** 사주시계 팝업  */
const setSajuClock = (state) => (isPopSajuClock.value = state);

const createFiveElements = (fiveData) => {
  if (!fiveData) return;
};

/** '일진달력' 버튼 클릭 */
const clickTodayLuckCalendar = () => {
  // isTodayLuckChecked = true;
  openInnerView();
};
</script>

<template>
  <div class="calendar-wrap v2">
    <div class="content-main mb-large">
      <section class="inner-base-calendar v2">
        <div class="com-tit-exp short" />
        <div class="calendar-sidebar-layout">
          <CalendarSidebar
            :isTodayLuckChecked="isTodayLuckChecked"
            :toggleOptions="toggleOptions"
            :sajuData="sajuData"
            :isOpenSajubox="isOpenSajubox"
            @openTodayLuck="clickTodayLuckCalendar"
            @closeTodayLuck="isTodayLuckChecked = false"
            @openSajuBox="openPopMySaju"
            @closeSajuBox="isOpenSajubox = !isOpenSajubox"
          />

          <div class="calendar-main">
            <CalendarHeader
              :calendarType="'v2'"
              :title="viewTitle"
              :isToday="isToday()"
              :solarTerm="firstSeason"
              :solarTermMonth="firstSeasonMonth"
              :isColorChecked="isColorChecked"
              @prev="prev"
              @next="next"
              @today="today"
              @clock="setSajuClock(true)"
            />

            <div class="sec-sch-body">
              <v-row
                class="fill-height"
                @mousedown="handleStart"
                @mousemove="handleMove"
                @mouseup="handleEnd"
                @touchstart="handleStart"
                @touchmove="handleMove"
                @touchend="handleEnd"
              >
                <v-col>
                  <v-sheet height="700">
                    <FullCalendar
                      ref="refCalendar"
                      class="full-calendar"
                      :options="calendarOptions"
                    />
                  </v-sheet>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div v-if="isLoading" class="loading-full-wrap">
      <div class="inner-box">
        <div ref="loadingContainer" class="loading-container">
          <v-progress-circular :size="50" :width="3" color="primary" indeterminate />
        </div>
      </div>
    </div>

    <SajuClockPopup
      v-if="isPopSajuClock"
      ref="currentClockRef"
      @closeClock="setSajuClock(false)"
    />

    <AlertComponent
      ref="dialogAlert"
      :title="dialogTitle"
      :text="dialogText"
    />
  </div>
</template>

<style>
.v-dialog-alert .alert-wrap .tit-box {
  white-space: pre-wrap;
}
</style>

<style lang="scss">
.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent;
}
.v-calendar-monthly .v-calendar-weekly__head-weekday {
  height: 38px;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #23252e;
  font-size: 13px;
  font-weight: 500;
  border-bottom: 0.5px solid #e2e2e2;
}
.v-calendar-weekly__day-label {
  margin: 0 !important;
}
.v-calendar-weekly__day-label button.v-btn {
  display: none;
  margin: 0 !important;
}
.datePicker .v-card__text {
  padding: 20px !important;
}

.fc-day-sun {
  .fc-scrollgrid-sync-inner {
    .fc-col-header-cell-cushion {
      color: #eb4f5d;
    }
  }
}
.fc-day-sat {
  .fc-scrollgrid-sync-inner {
    .fc-col-header-cell-cushion {
      color: #5a6ef6;
    }
  }
}
.fc-col-header-cell {
  .fc-scrollgrid-sync-inner {
    .fc-col-header-cell-cushion {
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    a {
      color: inherit;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

.custom-day-cell.other {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  border-radius: 100%;
  cursor: pointer;
}
.custom-day-cell {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  border-radius: 100%;
  color: #23252e;
  cursor: pointer;
}
.custom-day-sunday,
.custom-day-holiday {
  color: #eb4f5d;
}
.custom-day-saturday {
  color: #5a6ef6;
}
.custom-day-highlight {
  background-color: #eb4c10;
  color: white;
}
.custom-day-selected {
  z-index: 100;
  background-color: #e1e1e1;
}
.custom-content-cell {
  height: 100%;
}
.fc-daygrid-event {
  box-shadow: none !important;
}
.fc-event::before,
.fc-event::after,
.custom-event-content::before,
.custom-event-content::after {
  // content: none !important;
  // display: none !important;
  background: transparent !important;
  box-shadow: none !important;
}
a.fc-event:focus {
  outline: none !important;
  box-shadow: none !important;
}

.custom-event-content {
  .custom-content-cell {
    .content-dayju {
      position: absolute;
      top: 8px;
      height: 28px;
      color: #23252e;

      .txt-chn {
        font-size: 18px;
      }
      
      .txt-kor {
        font-size: 14px;
        margin-left: 5px;
      }

      &.today-luck {
        color: var(--Gray-500, #949494);
        text-align: center;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.333px;
      }
    }
  }
}
.content-lunar {
  position: absolute;
  // top: 36px;
  top: 32%;
  left: 0;
  right: 0;
}
.content-holiday {
  font-size: 14px;
  position: absolute;
  left: 0;
  top: 54%;
  right: 0;
  margin-bottom: 0 !important;
}
.content-season {
  position: absolute;
  left: 0;
  top: 76%;
  right: 0;
}

table tbody tr {
  height: 3px;
}
/** FullCalendar 격자 설정 */
.fc-theme-standard .fc-scrollgrid {
  border-left: 1px solid var(--Gray-300, #EBEBEB);
  border-top: 1px solid var(--Gray-300, #EBEBEB);
}
.fc-theme-standard th {
  border: 1px solid var(--Gray-300, #EBEBEB);
}
.fc-theme-standard td {
  border: 1px solid var(--Gray-300, #EBEBEB);
}
</style>